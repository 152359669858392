import type { OptionsProps } from '@dreamstack/feature-components'
import { DropdownButton } from '@dreamstack/feature-components'
import last from 'lodash/last'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import 'twin.macro'
import { HeadingH4 } from '../../layout/Texts'

export const PropertyFilterDropdown: FunctionComponent<
  React.PropsWithChildren<{
    onChange: (newValue: [number, number]) => void
    title: string
    subTitle?: string
    label?: [string, string]
    allOptions: OptionsProps[]
  }>
> = ({ onChange, label, title, subTitle, allOptions }) => {
  // make a useState with type of array of any type
  const [selectedMinValue, setSelectedMinValue] = useState(allOptions[0])
  const [selectedMaxValue, setSelectedMaxValue] = useState(
    last(allOptions) as OptionsProps
  )

  const minLabel = label?.[0] ?? 'Min'
  const maxLabel = label?.[1] ?? 'Max'

  useEffect(() => {
    onChange([selectedMinValue.value, selectedMaxValue.value])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMinValue, selectedMaxValue]) // dont add onChange here, otherwise it will fire onChange on every render

  return (
    <div tw="w-full ">
      <HeadingH4>
        {title}
        {subTitle && <span tw="font-semibold"> {subTitle}</span>}
      </HeadingH4>
      <div tw="flex flex-col sm:(flex-row gap-x-px-24)">
        {/* We need this input so that the headless-ui Listbox is functioning correctly. Otherwise, the first Listbox is always focused and opened after any change */}
        <input tw="hidden" />
        <DropdownButton
          label={minLabel}
          selectValue={selectedMinValue}
          setSelectValue={setSelectedMinValue}
          options={allOptions}
          partnerDropdownActiveValue={{
            value: selectedMaxValue.value,
            partnerIsMax: true,
          }}
        />
        <DropdownButton
          label={maxLabel}
          selectValue={selectedMaxValue}
          setSelectValue={setSelectedMaxValue}
          options={allOptions}
          partnerDropdownActiveValue={{
            value: selectedMinValue.value,
            partnerIsMax: false,
          }}
        />
      </div>
    </div>
  )
}
