import { useTranslation } from '@dreamstack/i18n'
import { SimpleRadio } from '@dreamstack/simple-components'
import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { UnControlledEmail } from '../../auth/forms/ControlledEmail'
import { usePropertySearchContext } from '../../next/PropertySearchContext'
import type { RentedTypes } from '../../next/PropertySearchFilter'
import { MaxFilterValue } from '../../next/PropertySearchFilter'
import { PropertySearchAutocomplete } from '../../property/PropertySearchAutocomplete'
import { PropertyFilterDropdown } from '../../property/filter/PropertyFilterDropdown'
import { useGetSearchProfileValueOptions } from './SearchProfileValueOptions'
import { useGetPreferenceSearchQuery } from './useGetPreferenceSearchQuery'

type InputFormProps = {
  show: boolean
  addForAnonymousUser?: boolean
}

const Form = styled.div<{ show?: boolean }>(({ show }) => [
  tw`flex flex-wrap gap-px-24`,
  !show && tw`hidden`,
])
const Row = tw.div`flex flex-1 flex-col md:flex-row gap-px-24 min-w-full`

export const InputForm = ({ show, addForAnonymousUser }: InputFormProps) => {
  const { propertySearchFilterValues, setPropertySearchFilterValues } =
    usePropertySearchContext()
  const t = useTranslation()
  const { preference } = useGetPreferenceSearchQuery()
  const searchProfileOptions = useGetSearchProfileValueOptions()
  const { LIVING_SPACE_OPTIONS, NUMBER_OF_ROOMS_OPTIONS, PRICE_OPTIONS } =
    searchProfileOptions

  const radioOptionsRented: {
    label: string
    value: RentedTypes
  }[] = React.useMemo(
    () => [
      { label: t('accentro:rented'), value: 'rented' },
      { label: t('accentro:not-rented'), value: 'not-rented' },
      { label: t('accentro:both'), value: 'both' },
    ],
    [t]
  )
  useEffect(() => {
    if (preference)
      setPropertySearchFilterValues({
        roomRange: [
          preference.number_of_rooms ?? 0,
          preference.number_of_rooms_to ?? MaxFilterValue.roomRange,
        ],
        livingspace: [
          preference.living_space ?? 0,
          preference.living_space_to ?? MaxFilterValue.livingSpace,
        ],
        priceRange: [
          preference.price ?? 0,
          preference.price_to ?? MaxFilterValue.price,
        ],
        rented:
          preference?.rented === 'true'
            ? 'rented'
            : preference?.rented === 'false'
            ? 'not-rented'
            : 'both',
        regions: preference.regions ?? undefined,
      })
  }, [preference, setPropertySearchFilterValues])
  return (
    <Form show={show}>
      {addForAnonymousUser ? (
        <Row>
          <InputFormPart
            label={t('accentro:my.myProfile.userDataSection.emailadress')}
          >
            <UnControlledEmail />
          </InputFormPart>
        </Row>
      ) : null}
      <Row>
        <InputFormPart
          label={`${t('accentro:region')}, ${t('accentro:city')}, ${t(
            'accentro:location'
          )}`}
        >
          <PropertySearchAutocomplete />
        </InputFormPart>
      </Row>
      <Row>
        <InputFormPart>
          <PropertyFilterDropdown
            title={t('accentro:room.rooms')}
            label={[
              t('accentro:room.rooms') + ' min',
              t('accentro:room.rooms') + ' max',
            ]}
            onChange={(v) => {
              setPropertySearchFilterValues({ roomRange: v })
            }}
            allOptions={NUMBER_OF_ROOMS_OPTIONS}
          />
        </InputFormPart>
        <InputFormPart>
          <PropertyFilterDropdown
            title={t('accentro:living-space')}
            label={[
              t('accentro:living-space') + ' min',
              t('accentro:living-space') + ' max',
            ]}
            subTitle={`in ${t('accentro:squareMeter')}`}
            onChange={(v) => {
              setPropertySearchFilterValues({ livingspace: v })
            }}
            allOptions={LIVING_SPACE_OPTIONS}
          />
        </InputFormPart>
      </Row>
      <Row>
        <InputFormPart>
          <PropertyFilterDropdown
            title={t('accentro:purchase-price')}
            label={[
              t('accentro:purchase-price') + ' min',
              t('accentro:purchase-price') + ' max',
            ]}
            subTitle={'in EUR'}
            onChange={(v) => {
              setPropertySearchFilterValues({ priceRange: v })
            }}
            allOptions={PRICE_OPTIONS}
          />
        </InputFormPart>
      </Row>
      <Row>
        <InputFormPart label={t('accentro:renting-state')}>
          <SimpleRadio
            radioGroupProps={{
              value: propertySearchFilterValues.rented,
              onChange: (e, v) =>
                setPropertySearchFilterValues({
                  rented: v as RentedTypes,
                }),
              defaultValue: 'both',
              name: 'rented',
              row: false,
            }}
            options={radioOptionsRented}
          />
        </InputFormPart>
      </Row>
    </Form>
  )
}

type InputFormPartProps = {
  label?: string
  children?: React.ReactNode
}

const InputFormPart = (props: InputFormPartProps) => {
  return (
    <label tw="flex flex-1 flex-col gap-px-16">
      {props.label && <span tw="font-semibold">{props.label}</span>}
      {props.children}
    </label>
  )
}
