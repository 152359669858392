import tw from 'twin.macro'

export const VeryLightGrayBox = tw.div`
    bg-accentroAquaVeryLight-full 
    font-size[20px]
    col-span-full
    px-px-16
    py-px-24
    md:p-px-32
    flex
    flex-col
    items-start
    gap-px-24`
