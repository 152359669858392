import { useCallback } from 'react'
import { useLocale } from '@dreamstack/i18n'

export const useGetFormattedToLocal = () => {
  const locale = useLocale()
  return useCallback(
    (val: string | number | Date) => {
      return val.toLocaleString(locale === 'de' ? 'de-DE' : 'en-GB')
    },
    [locale]
  )
}
