import { useGetFormattedToLocal } from '@dreamstack/accentro-contentful/src/blocks/CustomBlocks/RegionGraphs/libs/useGetFormattedToLocal'
import type { OptionsProps } from '@dreamstack/feature-components';
import { MaxFilterValue } from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'

export const useGetSearchProfileValueOptions = () => {
  const formatToLocalString = useGetFormattedToLocal()
  const t = useTranslation()
  return {
    PRICE_OPTIONS: [
      { id: 1, name: '0 EUR', value: 0 },
      { id: 2, name: `${formatToLocalString(50000)} EUR`, value: 50000 },
      { id: 3, name: `${formatToLocalString(100000)} EUR`, value: 100000 },
      { id: 4, name: `${formatToLocalString(150000)} EUR`, value: 150000 },
      { id: 5, name: `${formatToLocalString(200000)} EUR`, value: 200000 },
      { id: 6, name: `${formatToLocalString(250000)} EUR`, value: 250000 },
      { id: 7, name: `${formatToLocalString(300000)} EUR`, value: 300000 },
      { id: 8, name: `${formatToLocalString(350000)} EUR`, value: 350000 },
      { id: 9, name: `${formatToLocalString(400000)} EUR`, value: 400000 },
      { id: 10, name: `${formatToLocalString(450000)} EUR`, value: 450000 },
      { id: 11, name: `${formatToLocalString(500000)} EUR`, value: 500000 },
      { id: 12, name: `${formatToLocalString(550000)} EUR`, value: 550000 },
      { id: 13, name: `${formatToLocalString(600000)} EUR`, value: 600000 },
      { id: 14, name: `${formatToLocalString(700000)} EUR`, value: 700000 },
      { id: 15, name: `${formatToLocalString(800000)} EUR`, value: 800000 },
      { id: 16, name: `${formatToLocalString(900000)} EUR`, value: 900000 },
      {
        id: 17,
        name: t('accentro:propertyFilter.anyValue'),
        value: MaxFilterValue.price,
      },
    ] as OptionsProps[],
    NUMBER_OF_ROOMS_OPTIONS: [
      { id: 1, name: '0', value: 0 },
      { id: 2, name: '1', value: 1 },
      { id: 3, name: '2', value: 2 },
      { id: 4, name: '3', value: 3 },
      { id: 5, name: '4', value: 4 },
      { id: 6, name: '5', value: 5 },
      { id: 7, name: '6', value: 6 },
      { id: 8, name: '7', value: 7 },
      { id: 9, name: '8', value: 8 },
      { id: 10, name: '9', value: 9 },
      {
        id: 11,
        name: t('accentro:propertyFilter.anyValue'),
        value: MaxFilterValue.roomRange,
      },
    ] as OptionsProps[],
    LIVING_SPACE_OPTIONS: [
      { id: 1, name: '0 m²', value: 0 },
      { id: 2, name: '50 m²', value: 50 },
      { id: 3, name: '100 m²', value: 100 },
      { id: 4, name: '150 m²', value: 150 },
      { id: 5, name: '200 m²', value: 200 },
      { id: 6, name: '400 m²', value: 400 },
      { id: 7, name: '600 m²', value: 600 },
      { id: 8, name: '800 m²', value: 800 },
      {
        id: 9,
        name: t('accentro:propertyFilter.anyValue'),
        value: MaxFilterValue.livingSpace,
      },
    ] as OptionsProps[],
  }
}
