import { useIsLoggedInWithLoading } from '@dreamstack/auth'
import { trpc } from '@dreamstack/trpc/client'

export const useGetPreferenceSearchQuery = () => {
  const { isLoggedIn } = useIsLoggedInWithLoading()
  const { data } = trpc.savedQueries.getMySavedQueries.useQuery(undefined, {
    trpc: { ssr: false },
    staleTime: 0,
    enabled: isLoggedIn,
  })
  const savedQueries = data?.data
  const preference = savedQueries?.find((query) => query.note === 'preference')

  return { preference }
}
