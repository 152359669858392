import { ChevronDown, ChevronUp } from '@dreamstack/icons'
import { Listbox, Transition } from '@headlessui/react'
import map from 'lodash/map'
import type { FunctionComponent } from 'react';
import { Fragment } from 'react'
import tw from 'twin.macro'
import { BodyText } from './Texts'

export type OptionsProps = {
  id: number
  name: string | number
  value: number
}

export const DropdownButton: FunctionComponent<React.PropsWithChildren<{
  selectValue: OptionsProps
  setSelectValue: React.Dispatch<React.SetStateAction<OptionsProps>>
  options: OptionsProps[]
  label?: string
  partnerDropdownActiveValue?: { value: number; partnerIsMax: boolean }
}>> = ({
  selectValue,
  setSelectValue,
  options,
  label,
  partnerDropdownActiveValue,
}) => {
  const isMax = partnerDropdownActiveValue?.partnerIsMax

  return (
    <div tw="w-full">
      <Listbox by="value" value={selectValue} onChange={setSelectValue}>
        {({ open }) => (
          <div tw="relative">
            {label && (
              <Listbox.Label tw="text-left text-xs text-accentroGray-500">
                {label}
              </Listbox.Label>
            )}

            <Listbox.Button tw="w-full py-px-8 px-px-20 text-left bg-accentroGray-50 relative cursor-default flex flex-row justify-between">
              <BodyText> {selectValue.name}</BodyText>
              <span tw="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 flex-shrink-0">
                {open ? (
                  <ChevronUp tw="h-px-32 w-px-32 text-gray-400" />
                ) : (
                  <ChevronDown tw="h-px-32 w-px-32 text-gray-400" />
                )}
              </span>
            </Listbox.Button>
            <Transition
              leave="transition ease-in duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options tw="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1  z-10 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {map(options, (option) => (
                  <Listbox.Option
                    //   @ts-ignore
                    as={Fragment}
                    key={option.id}
                    value={option}
                    disabled={
                      partnerDropdownActiveValue
                        ? isMax
                          ? option.value > partnerDropdownActiveValue?.value
                          : option.value < partnerDropdownActiveValue?.value
                        : false
                    }
                  >
                    {({ active, disabled }) => (
                      <li
                        tw="relative py-2 px-3 font-semibold"
                        css={[
                          active && tw`bg-accentroGray-50 `,
                          !active && tw`bg-white text-black`,
                          disabled &&
                            tw`text-accentroGray-200 bg-accentroGray-50 cursor-not-allowed`,
                        ]}
                      >
                        <BodyText>{option.name}</BodyText>
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  )
}
